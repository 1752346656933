import {
  GET_SUBSCRIPTION_PLANS,
  GET_CURRENT_SUBSCRIPTION,
  SUBSCRIPTION_CANCELLED,
  SUBSCRIPTION_UPDATED,
  SUBSCRIPTION_RESUMED,
  UPDATE_URL_RETRIEVED,
} from '../actions/types.js';
import { BASIC_PERMISSIONS, PREMIUM_PERMISSIONS, STANDARD_PERMISSIONS } from '../constants/subscriptions';
import { getRegionSpecificSubscriptionInclusions } from '../utils/configurationHelpers.js';
import { allotObjectToTier } from '../utils/helpers.js';

const initialState = {
  currentSubscription: null,
  pastSubscriptions: null,
  subscriptionPlans: [],
  updateUrl: null,
  permissions: [],
  packageInclusions: getRegionSpecificSubscriptionInclusions(),
};

const determinePermissions = (subscriptionPlanName, subscriptionStatus) => {
  if (subscriptionStatus == 'active' || subscriptionStatus == 'trialing') {
    return allotObjectToTier(subscriptionPlanName, BASIC_PERMISSIONS, STANDARD_PERMISSIONS, PREMIUM_PERMISSIONS);
  }
  return [];
};

export default function subscription(state = initialState, action) {
  switch (action.type) {
    case GET_SUBSCRIPTION_PLANS:
      return {
        ...state,
        subscriptionPlans: action.payload,
      };
    case GET_CURRENT_SUBSCRIPTION:
    case SUBSCRIPTION_UPDATED:
    case SUBSCRIPTION_CANCELLED:
    case SUBSCRIPTION_RESUMED:
      let permissions = determinePermissions(action.payload.price?.product?.name, action.payload.status);
      return {
        ...state,
        currentSubscription: action.payload,
        permissions: permissions,
      };
    case UPDATE_URL_RETRIEVED:
      return {
        ...state,
        updateUrl: action.payload,
      };

    default:
      return state;
  }
}
