import React from 'react';

import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

import Loading from '../organisms/Loading/Loading';
import { useSelector } from 'react-redux';

const getVariantStyle = (theme, variant) => {
  switch (variant) {
    case 'accent':
      return {
        backgroundColor: theme.palette.primary.main,
        fontWeight: 'bold',
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        boxShadow: theme.shadows[0],
        color: 'white',
      };
    case 'disabled':
      return { backgroundColor: theme.palette.primary.disabled };
    case 'warning':
      return {
        backgroundColor: 'white',
        color: theme.palette.error.dark,
        boxShadow: theme.shadows[1],

        '&:hover': {
          backgroundColor: theme.palette.error.dark,
          color: 'white',
        },
      };
    case 'noBG':
      return {
        backgroundColor: 'hsla(120, 100%, 50%, 0)',

        '&:hover': {
          backgroundColor: 'hsla(120, 100%, 50%, 0)',
          color: theme.palette.button.dark,
        },
      };
    case 'primary':
      return {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        '&.Mui-disabled': {
          color: theme.palette.text.hint,
          backgroundColor: '#D5D4DE',
        },
      };
    case 'text':
      return {
        background: 'none',
        color: theme.palette.text.primary,
        '&:hover': {
          backgroundColor: 'inherit',
        },
        minHeight: 0,
      };
    case 'link':
      return {
        color: theme.palette.primary.main,
        background: 'none',
        '&:hover': {
          backgroundColor: 'inherit',
        },
        fontWeight: 400,
      };
    default:
      return {};
  }
};

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => !['customVariant', 'maxWidth', 'noMargin', 'noPadding'].includes(prop),
})(({ theme, customVariant, maxWidth, noMargin, noPadding }) => ({
  width: '100%',
  minWidth: '5ch',
  maxWidth: maxWidth,
  minHeight: theme.spacing(5),
  margin: noMargin ? 0 : theme.spacing(1),
  padding: noPadding ? 0 : theme.spacing(1, 2),
  textTransform: 'capitalize',
  fontWeight: '700',
  fontFamily: theme.typography.fontFamily,
  borderRadius: '10px',
  backgroundColor: theme.palette.background.dark,

  '&:hover': {
    backgroundColor: theme.palette.button.light,
  },

  ...getVariantStyle(theme, customVariant),
}));

const SmallButton = (props) => {
  const { styles } = useSelector((state) => state.handlingTranslation);
  const {
    customVariant,
    maxWidth = styles.component_SmallButton?.width,
    className,
    children,
    showLoading,
    ...muiButtonProps
  } = props;

  return (
    <StyledButton
      {...muiButtonProps}
      maxWidth={maxWidth}
      color={'primary'}
      customVariant={customVariant}
      className={className}
      endIcon={showLoading ? null : muiButtonProps.endIcon}
      startIcon={showLoading ? null : muiButtonProps.startIcon}
      disabled={showLoading || muiButtonProps.disabled}
    >
      {showLoading ? <Loading size="xs" /> : children}
    </StyledButton>
  );
};

export default SmallButton;
