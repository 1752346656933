// DIABETES TYPES

export const TYPE_1_DIABETES = 1;
export const TYPE_2_DIABETES = 2;
export const NO_DIABETES = 3;
export const GESTATIONAL_DIABETES = 4;

export const DIABETES_TYPE_UNKNOWN = 5;

export const DIABETES_TYPE_NUM = {
  'Type 1': TYPE_1_DIABETES,
  'Type 2': TYPE_2_DIABETES,
  'No Diabetes': NO_DIABETES,
  Gestational: GESTATIONAL_DIABETES,
  '-': DIABETES_TYPE_UNKNOWN,
};

// DR PRESENCE
export const NO_DISEASE = 1;
export const MILD = 2;
export const MODERATE = 3;
export const SEVERE = 4;
export const PDR = 5;

export const DR_PRESENCE_STRING_TO_NUM = {
  'No Disease': NO_DISEASE,
  'Mild Retinopathy': MILD,
  'Moderate Retinopathy': MODERATE,
  'Severe Retinopathy': SEVERE,
  'Proliferative Retinopathy': PDR,
};

// ME PRESENCE
export const ABSENT = 1;
export const PRESENT = 2;

export const ME_PRESENCE_STRING_TO_NUM = {
  'Diabetic Macular Edema Absent': ABSENT,
  'Diabetic Macular Edema Present': PRESENT,
};

// DIABETES THERAPY TYPES
export const DIET = 1;
export const INSULIN = 2;
export const MEDICATION = 3;

export const DIABETES_THERAPY_TYPE_STR = {
  [DIET]: 'Diet',
  [MEDICATION]: 'Medication',
  [INSULIN]: 'Insulin',
};

// PARAMETER TYPES
export const HBA1C = 1;
export const BLOOD_PRESSURE = 2;
export const ALBUMINURIA = 3;
export const DIABETES_THERAPY = 4;
export const POSTPRANDIAL_BLOOD_GLUCOSE = 5;
export const TRIGLYCERIDE = 6;
export const LOW_DENSITY_LIPOPROTEIN = 7;
export const VISUAL_ACUITY = 8;
export const CORRECTED_VISUAL_ACUITY = 18;
export const UNCORRECTED_VISUAL_ACUITY = 19;
export const IOP = 9;
export const DIABETES_TYPE = 10;
export const DIABETES_ONSET = 11;
export const EYE_SURGERY_HISTORY = 12;
export const HIGH_BLOOD_PRESSURE = 13;
export const HEART_DISEASE = 14;
export const HIGH_CHOLESTEROL = 15;
export const DR_PRESENCE = 16;
export const MACULAR_EDEMA_PRESENCE = 17;

export const HEALTH_DATA_TYPE_STR = {
  // [VISUAL_ACUITY]: "Visual Acuity", deprecated
  [UNCORRECTED_VISUAL_ACUITY]: 'Visual Acuity (Uncorrected)',
  [CORRECTED_VISUAL_ACUITY]: 'Visual Acuity (Best Corrected)',
  [IOP]: 'IOP (mmHg)',
  [HBA1C]: 'HbA1c (%)',
  [BLOOD_PRESSURE]: 'Blood Pressure (mmHg)',
  [ALBUMINURIA]: 'Albuminuria (mg/mmol)',
  [DIABETES_THERAPY]: 'Diabetes Therapy',
  [POSTPRANDIAL_BLOOD_GLUCOSE]: 'Postprandial Blood Glucose (mg/dL)',
  [TRIGLYCERIDE]: 'Triglyceride (mg/dL)',
  [LOW_DENSITY_LIPOPROTEIN]: 'Low Density Lipoprotein (mg/dL)',
};

export const HEALTH_DATA_TYPE_VAR = {
  [HBA1C]: 'hba1c_value',
  [BLOOD_PRESSURE]: 'blood_pressure',
  [ALBUMINURIA]: 'albuminuria_value',
  [DIABETES_THERAPY]: 'diabetes_therapy',
  [POSTPRANDIAL_BLOOD_GLUCOSE]: 'postprandial_blood_glucose_value',
  [TRIGLYCERIDE]: 'triglyceride_value',
  [LOW_DENSITY_LIPOPROTEIN]: 'low_density_lipoprotein_value',
  [VISUAL_ACUITY]: 'visual_acuity_value',
  [CORRECTED_VISUAL_ACUITY]: 'corrected_visual_acuity_value',
  [UNCORRECTED_VISUAL_ACUITY]: 'uncorrected_visual_acuity_value',
  [IOP]: 'intraocular_pressure_value',
  [DIABETES_TYPE]: 'diabetes_type',
  [DIABETES_ONSET]: 'diabetes_onset',
  [EYE_SURGERY_HISTORY]: 'eye_surgery_history',
  [HEART_DISEASE]: 'heart_disease',
  [HIGH_BLOOD_PRESSURE]: 'high_blood_pressure',
  [HIGH_CHOLESTEROL]: 'high_cholesterol',
  [DR_PRESENCE]: 'dr_presence',
  [MACULAR_EDEMA_PRESENCE]: 'me_presence',
};

// VISUAL ACUITY FIELDS

export const VA_NONE = 0;
export const NO_LIGHT_PERCEPTION = 1;
export const LIGHT_PERCEPTION = 2;
export const HAND_MOVEMENTS = 3;
export const COUNTING_FINGERS = 4;
export const VA_6_120 = 5;
export const VA_6_60 = 6;
export const VA_6_45 = 7;
export const VA_6_36 = 8;
export const VA_6_24 = 9;
export const VA_6_18 = 10;
export const VA_6_15 = 11;
export const VA_6_12 = 12;
export const VA_6_9 = 13;
export const VA_6_7DOT5 = 14;
export const VA_6_6 = 15;
export const VA_6_48 = 16;

export const VISUAL_ACUITY_CHOICES = {
  [VA_NONE]: 'None',
  [NO_LIGHT_PERCEPTION]: 'No light perception (NLP)',
  [LIGHT_PERCEPTION]: 'Light perception (LP)',
  [HAND_MOVEMENTS]: 'Hand movements (HM)',
  [COUNTING_FINGERS]: 'Counting fingers at 1 meters (CF)',
  [VA_6_120]: '6/120',
  [VA_6_60]: '6/60',
  [VA_6_45]: '6/45',
  [VA_6_36]: '6/36',
  [VA_6_24]: '6/24',
  [VA_6_18]: '6/18',
  [VA_6_15]: '6/15',
  [VA_6_12]: '6/12',
  [VA_6_9]: '6/9',
  [VA_6_7DOT5]: '6/7.5',
  [VA_6_6]: '6/6',
  [VA_6_48]: '6/4.8',
};
