import ReviewsAccordion from '../components/layout/molecules/ReviewsAccordion';

//This is for best UI,when the Language is changing apply the suitable styles adjust UI
const PREFIX = 'component';
const componentNames = {
  ExamsByOperator: `${PREFIX}_ExamsByOperator`,
  ExamAccordion: `${PREFIX}_ExamAccordion`,
  ReviewsAccordion: `${PREFIX}_ReviewsAccordion`,
  LargeButton: `${PREFIX}_LargeButton`,
  AddNewField: `${PREFIX}_AddNewField`,
  SmallButton: `${PREFIX}_SmallButton`,
  PatientExams: `${PREFIX}_PatientExams`,
};

export const customLanguagestyles = {
  en: {
    [componentNames.ExamsByOperator]: {
      date: { width: '20%' },
      patient: { width: '30%', fontWeight: 'bold', textTransform: 'capitalize' },
      practitioner: { width: '28%', fontWeight: 'bold', textTransform: 'capitalize' },
      status: { width: '22%' },
    },
    [componentNames.PatientExams]: {
      label_1: {
        width: 5,
      },
      label_2: {
        width: 7,
      },
    },

    [componentNames.ExamAccordion]: {
      All_Exams: {
        xs: {
          sectionOne: 8,
          sectionTwo: 4,
        },
        xl: {
          sectionOne: 9,
          sectionTwo: 3,
        },
      },
    },
    [componentNames.ReviewsAccordion]: {
      sectionOne: 9.5,
      sectionTwo: 2.5,
    },
    [componentNames.LargeButton]: {
      width: '27ch',
    },
    [componentNames.AddNewField]: {
      input: 10,
      button: 2,
    },
    [componentNames.SmallButton]: {
      width: '20ch',
    },
  },
  de: {
    [componentNames.ExamsByOperator]: {
      date: { width: '23%' },
      patient: { width: '27%', fontWeight: 'bold', textTransform: 'capitalize' },
      practitioner: { width: '24%', fontWeight: 'bold', textTransform: 'capitalize' },
      status: { width: '26%' },
    },
    [componentNames.PatientExams]: {
      label_1: {
        width: 6,
      },
      label_2: {
        width: 6,
      },
    },

    [componentNames.ExamAccordion]: {
      All_Exams: {
        xs: {
          sectionOne: 7.7,
          sectionTwo: 4.3,
        },
        xl: {
          sectionOne: 7.7,
          sectionTwo: 4.3,
        },
      },
    },
    [componentNames.ReviewsAccordion]: {
      sectionOne: 9,
      sectionTwo: 3,
    },
    [componentNames.LargeButton]: {
      width: '30ch',
    },
    [componentNames.AddNewField]: {
      input: 9.5,
      button: 2.5,
    },
    [componentNames.SmallButton]: {
      width: '24ch',
    },
  },
};
